<template>
  <div>
    <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">报告管理</span>
      </div>
      <a-button-group>
        <a-button style="margin-left:10px;padding-left: 10px; " type="primary" v-hasPermi="['report_add']" @click="newReport" >新建</a-button>
      </a-button-group>
    </div> -->
    <div class="table-search">
      <a-form-model size="small" layout="inline" label-width="68px">
        <a-form-model-item label="催化剂类型：" prop="">
          <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
            <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始时间：" prop="">
          <a-date-picker
            v-model="startValue"
            :disabled-date="disabledStartDate"
            show-time
            valueFormat="YYYY-MM-DD HH:mm:ss"
            @openChange="handleStartOpenChange"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间：" prop="">
          <a-date-picker
            v-model="endValue"
            :disabled-date="disabledEndDate"
            show-time
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :open="endOpen"
            @openChange="handleEndOpenChange"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>
        </a-form-model-item>
        <a-form-model-item style="float: right;margin-right: 0px;">
          <a-button style="width: 150px;height: 35px;margin-left:10px;padding-left: 10px;font-size:1.5em; " type="primary" icon="plus-square" v-hasPermi="['report_add']" @click="newReport" >新增</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- <div id="queryDiv">
      <span style="padding-left: 20px">催化剂类型：</span>
      <a-select v-model:value="catalystValue" style="width:150px;" placeholder="请选择催化剂类型" show-search :allowClear="true">
        <a-select-option v-for="item in catalysts" :key="item.id" :value="item.id">{{ item.value }}
        </a-select-option>
      </a-select>
      <span style="margin: 0 10px">开始时间：</span>
      <a-date-picker
        v-model="startValue"
        :disabled-date="disabledStartDate"
        show-time
        valueFormat="YYYY-MM-DD HH:mm:ss"
        @openChange="handleStartOpenChange"
      />
      <span style="margin: 0 10px">结束时间：</span>
      <a-date-picker
        v-model="endValue"
        :disabled-date="disabledEndDate"
        show-time
        valueFormat="YYYY-MM-DD HH:mm:ss"
        :open="endOpen"
        @openChange="handleEndOpenChange"
      />
      <a-button type="primary" icon="search" style="margin: 0 10px" @click="search">查询</a-button>
    </div> -->
    <!--    报告的数据表格-->
  <a-spin :spinning="loading" tip="数据获取中，请稍候......" >
    <div class="card" style="padding:6px ;height:calc(100vh - 160px)">
      <a-table rowKey="id" :columns="reportColumns" :dataSource="reportDatas" :row-selection="rowSelectionConfig"
               ref="eleReport" :pagination="paginationOpt" bordered>
        <template v-slot:action="record">
          <a href="javascript:;" @click="handleViewReport(record)" v-hasPermi="['report_view']"><a-icon type="eye" /> 查看</a>
          <a href="javascript:;" @click="handleEditReport(record)" v-hasPermi="['report_edit']"><a-icon type="form" /> 编辑</a>
          <a href="javascript:;" @click="handelDeleteReport(record.id)" v-hasPermi="['report_delete']"><a-icon type="delete" /> 删除</a>
        </template>
      </a-table>
    </div>
  </a-spin>
    <report-dialog :show-modal="showReport" :title="reportTitle" :sample-type="sampleType" :id="reportId" :report-view="reportView"/>
    <folder-divide-dialog :show-modal="showFolderDivide" :title="reportTitle"/>
  </div>
</template>


<script lang="ts">

import {getCatalystsData, getFilesData, getReportList,deleteReport} from "../utils/data-manager";
import ReportDialog from "./ReportDialog.vue";
import { Modal } from 'ant-design-vue';
import FolderDivideDialog from "./FolderDivideDialog.vue";

export default {
  components: {
    FolderDivideDialog,
    ReportDialog
  },
  data() {
    return {
      loading:false,
      reportColumns:[
        // {
        //   title: '文件名称',
        //   dataIndex: 'folder_name',
        // },
        {
          title: '类型',
          dataIndex: 'catalyst_type',
        },
        {
          title: '报告单号',
          dataIndex: 'report_id',
        },
/*
        {
          title: '催化剂编号',
          dataIndex: 'catalyst_id',
        },
*/
        {
          title: '送检科室',
          dataIndex: 'department',
        },
        {
          title: '送样人员',
          dataIndex: 'operator',
        },
        {
          title: '是否生成报告单',
          dataIndex: 'is_generate',
        },
        {
          title: '生成日期',
          dataIndex: 'generate_date',
        },
        {
          title: '操作',
          key: 'operation',
          width:'180px',
          align:'center',
          className:'operation-cls',
          scopedSlots: { customRender: 'action'}
        }
      ],
      showFolderDivide:false,
      showReport:false,
      reportId:null,
      sampleType:0,
      reportDatas:[],
      selectedReportIds:[],
      reportView:true,
      reportTitle:"",
      // 分页
      paginationOpt: {
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 20, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["20", "50", "100"],
        showTotal: (total, range) => `每页${this.paginationOpt.defaultPageSize}条，共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.defaultCurrent = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.search();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.defaultCurrent = current;
          this.paginationOpt.defaultPageSize = size;
          this.search();
        },
      },
      startValue: null,
      endValue: null,
      endOpen: false,
      catalysts: [],
      catalystValue: null,
      files: [],
      multiple: true,
      fileValue: null
    };
  },
  async created() {
    this.catalysts =await getCatalystsData();
    await this.search();
  },
  computed: {
    rowSelectionConfig() {
      return {
        selectedRowKeys: this.selectedReportIds,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedReportIds=selectedRowKeys;
          this.multiple = !this.selectedReportIds.length;
        }
      }
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async handleViewReport(data){
      this.reportId=data.id;
      this.sampleType=data.sampleType;
      if(!this.sampleType){
        this.sampleType=0;
      }
      this.reportTitle="报告查看";
      this.reportView=true;
      this.showReport=true;
    },
    async handleEditReport(data){
      this.reportId=data.id;
      this.sampleType=data.sampleType;
      if(!this.sampleType){
        this.sampleType=0;
      }
      this.reportTitle="报告编辑";
      this.reportView=false;
      this.showReport=true;
    },
    handelDeleteReport(id){
      Modal.confirm({
        title:"提示信息",
        content:"确认要删除选中的记录吗？",
        centered:true,
        onOk: async()=>{
          let data=await deleteReport(id);
          if(data){
            if(data.flag){
              this.search();
            }else{
              let infoTitle="操作失败";
              Modal.info({
                title:infoTitle,
                content: data.msg,
                centered:true
              });
            }
          }
        }
      });
    },
    newReport(id){
      this.reportTitle="报告新增";
      this.showFolderDivide=true;
    },
/*
    async createReport(){//生成报告
      // console.log("createReport",this.selectedReportIds);
      if(this.selectedReportIds&&this.selectedReportIds.length>0){
        let data=await createReport(this.selectedReportIds.join());
        if(data){
          let infoTitle="操作失败";
          if(data.flag){
            infoTitle="操作成功";
          }
          Modal.info({
            title:infoTitle,
            content: data.msg,
            centered:true
          });
          if(data.flag){
            this.search();
          }
        }
      }else{
        Modal.info({
          title:"提示信息",
          content:"必须选择要生成报告的数据行！",
          centered:true,
        });
      }
    },
*/
    closeReport(){//子组件调用
      this.showReport=false;
    },
    closeFolderDivide(){//子组件调用
      this.showFolderDivide=false;
    },
    async search() {
      this.loading=true;
      let res=await getReportList(this.catalystValue, this.startValue, this.endValue, this.fileValue,this.paginationOpt.defaultCurrent,this.paginationOpt.defaultPageSize);
      this.loading=false;
      if(res.flag){
        this.reportDatas =res.data;
        this.paginationOpt.total=res.total;
      }else{
        Modal.error({
          title:"错误信息",
          content:res.msg,
          centered:true,
        });
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    async handleDropdownVisibleChange(open){
      if(open){
        this.files = await getFilesData(this.catalystValue, this.startValue, this.endValue);
      }
    },
    handleChange(value) {
      this.fileValue = value;
    }
  }
}
</script>

<style scoped>
#queryDiv{
  margin: 10px 0
}
#queryDiv span{
  white-space:nowrap;
}
.operation-cls{
  padding:0px;
  width:10px;
}
.operation-cls a{
  margin:0px 2px;
}
::v-deep .table-search .ant-input{
  width: 170px!important;
}
</style>
